import { ChromePicker } from "react-color"
import { ModalityConfigurationDiv } from "../../../../../Constants/StyledComponents"
import React, { useContext, useEffect, useRef, useState } from "react"
import { MobergRow, MobergDropdown, MobergButton, MobergButtonShape, MobergIconSize, MobergTheme, MobergButtonVariant, MobergInputLabel, MobergShortTextInput, MobergColumn } from "../../../../../Moberg"
import { LineTraceConfig, LineTraceConfigJSON, RenderStrategy, TraceConfigJSON, TraceOption } from "../../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { MdClose, MdSettings } from "react-icons/md"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import { useModalProvider } from "../../../../../Providers/ModalProvider"
import { EditAnalysisModal } from "../../../../../Pages/Data/Visualize/DataReview/Components/Modals/EditAnalysis/EditAnalysisModal"
import { OnDemandAnalysis } from "../../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails"
import { LineGraphModalitiesContext } from "./LineGraphModalities"

export const AnalyticLineModalities: React.FC = () => {
	const { traceOptions, currentGraph, updateGraphProperty, removeTrace } = useContext(ConfigureWindowModalContext)
	const { activeAnalyticTraces, updateTrace, handleColorChange } = useContext(LineGraphModalitiesContext)
	const [colorPickerTraceId, setColorPickerTraceId] = useState<string>()
	const colorPickerBlockRefs = useRef<Map<string, HTMLDivElement | null>>(new Map())
	const colorPickerRef = useRef<HTMLDivElement | null>(null)
	const { createModal } = useModalProvider()
	const analyticTraceOptions = traceOptions.filter((traceOption: TraceOption) => traceOption.value.onDemandAnalysis)

	function addModality() {
		updateGraphProperty("traces", (previous: TraceConfigJSON[]) => {
			return [
				...previous,
				{
					...analyticTraceOptions[0].value,
					id: `trace-${new Date(Date.now()).toISOString()}`,
					renderStrategy: RenderStrategy.LINE,
					color: "#000",
				},
			]
		})
	}

	function toggleColorPicker(event: any, traceId: string) {
		const node = colorPickerRef.current

		if (node?.style.display === "block" && traceId === colorPickerTraceId) {
			node.style.display = "none"
			return
		}

		setColorPickerTraceId(traceId)

		if (node) {
			node.style.display = "block"
			node.style.top = `${event.target.getBoundingClientRect().bottom}px`
			node.style.left = `${event.target.getBoundingClientRect().right}px`
		}
	}

	function addColorPickerBlockRef(node: HTMLDivElement | null, traceId: string) {
		colorPickerBlockRefs.current.set(traceId, node)
	}

	function traceAnalysisArgumentsChanged(trace: LineTraceConfig, newArgs: OnDemandAnalysis | undefined) {
		updateTrace(trace.id, {
            ...trace,
			onDemandAnalysis: newArgs,
			dataKey: JSON.stringify(newArgs)
		})
	}

	useEffect(() => {
		const clickHandler = (event: any) => {
			let clickedOutside = true

			colorPickerBlockRefs.current.forEach(node => {
				if (node?.contains(event.target)) {
					clickedOutside = false
				}
			})

			if (colorPickerRef.current?.contains(event.target)) {
				clickedOutside = false
			}

			if (clickedOutside && colorPickerRef.current) {
				colorPickerRef.current.style.display = "none"
			}
		}

		document.addEventListener("click", clickHandler)
		return () => document.removeEventListener("click", clickHandler)
	})

	return (
        <ModalityConfigurationDiv>
			<MobergColumn gap = "32px">
				<div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto 1fr", columnGap: "32px", rowGap: "8px" }}>

					<MobergInputLabel text={"Analytic"} />
					<MobergInputLabel text={"Label"} />
					<MobergInputLabel text={"Color"} />
					<MobergInputLabel text={"Calculation"} />
					<MobergInputLabel text={""} />

					{activeAnalyticTraces.map(trace => {
						return (
							<>
								<MobergDropdown
									options={analyticTraceOptions}
									onChange={(traceConfig: LineTraceConfigJSON) => updateTrace(trace.id, traceConfig)}
									selectedValue={trace}
									equals={(a: LineTraceConfigJSON, b: LineTraceConfigJSON) => 
										a.onDemandAnalysis?.analytic === b.onDemandAnalysis?.analytic
										&& (a.isCompositePart 
												? b.isCompositePart && a.compositeIndex === b.compositeIndex
												: true
											)
										}
									width={175}
								/>

								<MobergShortTextInput
									limit={20}
									defaultValue={trace.name}
									value={trace.name}
									onChange={(name: string) => updateTrace(trace.id, { ...trace, name })}
									style={{ width: "150px" }}
								/>

								<MobergRow expand={false}>
									<div
										onClick={event => toggleColorPicker(event, trace.id)}
										style={{
											width: "25px",
											height: "25px",
											backgroundColor: (trace as LineTraceConfigJSON).color ?? "#000",
											border: "1px solid #000",
											borderRadius: "25px",
											cursor: "pointer",
										}}
										ref={ref => addColorPickerBlockRef(ref, trace.id)}
									/>
								</MobergRow>

								<MobergButton
									tooltip={trace.onDemandAnalysis 
										? "Edit the calculation of " + trace.onDemandAnalysis.analytic 
										: "This modality is a raw signal."}
									shape={MobergButtonShape.SQUARE}
									onClick={() => {
										createModal(<EditAnalysisModal 
											analysis={trace.onDemandAnalysis}
											rawDataKey={trace.rawDataKey}
											onChange={(newArgs: OnDemandAnalysis | undefined) => traceAnalysisArgumentsChanged(trace, newArgs)}
										/>)
									}}
								>
									<MdSettings size={MobergIconSize.REGULAR} />
								</MobergButton>

								<MobergButton shape={MobergButtonShape.SQUARE} onClick={() => removeTrace(trace.id)} style={{ padding: "6px" }}>
									<MdClose size={MobergIconSize.REGULAR} />
								</MobergButton>
							</>
						)
					})}

					<MobergButton onClick={addModality} theme={MobergTheme.BLUE} variant={MobergButtonVariant.OUTLINE} style={{ width: "175px" }}>
						Add analytic
					</MobergButton>
				</div>
			</MobergColumn>


            <div id={`ColorPickerWindow`} ref={colorPickerRef} style={{ position: "fixed", display: "none", zIndex: 999 }}>
                <ChromePicker
                    color={((currentGraph?.traces ?? []).find(trace => trace.id === colorPickerTraceId) as LineTraceConfigJSON)?.color ?? "#000"}
                    onChange={color => {
                        if (colorPickerTraceId !== undefined) {
                            handleColorChange(colorPickerTraceId, color.hex)
                        }
                    }}
                />
            </div>
        </ModalityConfigurationDiv>
	)
}
