import { seriesCanvasArea } from "d3fc"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { BaseTraceConfig, DeltaRegion, PageRectangle, RenderStrategy, TraceDataConfig } from "../../../../Types/Trace"
import { D3Trace } from "./D3Trace"
import { fillInPageGaps, TraceRendererOptions, TraceRenderStrategy } from "./RenderStrategy"
import { bisectLeft, bisectRight, ScaleLinear, scaleLinear, ScaleTime } from "d3"
import { getGapIndexes, getTimeSeriesData, TimeSeriesData } from "../../../../Data/TimeSeriesData"
import { ModalityGraphGroupReactCallbacks } from "../../../../Types/ReactCallbacks"
import { LineRenderStrategy } from "./D3LineRenderStrategy"
import { getColors, MobergTheme } from "../../../../../../../../Moberg"

export class DeltaRenderStrategy implements TraceRenderStrategy {
	private directRenderer
	private offscreenRenderer
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private config: DeltaRegion
	private d3Trace: D3Trace
	private renderCacheKey: string = ""
	private placeholderColor = getColors(MobergTheme.GRAY).main

	constructor(pageManager: TimeSeriesPageManager<ModalityPage>, d3Trace: D3Trace, config: DeltaRegion) {
		this.config = config
		this.d3Trace = d3Trace
		this.pageManager = pageManager

		this.offscreenRenderer = seriesCanvasArea()
			.crossValue((p: [number, number, number]) => p[0])
			.mainValue((p: [number, number, number]) => p[1])
			.baseValue((p: [number, number, number]) => p[2])

		this.directRenderer = seriesCanvasArea()
			.crossValue((p: [number, number, number]) => p[0])
			.mainValue((p: [number, number, number]) => p[1])
			.baseValue((p: [number, number, number]) => p[2])

		this.updateRenderCacheKey()
	}

	public updateConfig(traceConfig: DeltaRegion) {
		this.config = traceConfig
		const { r, g, b } = this.config.color

		this.directRenderer
			.xScale(this.config.xScale)
			.yScale(this.config.yScale)
			.decorate((context: CanvasRenderingContext2D) => {
				context.strokeStyle = "transparent"
				context.fillStyle = `rgba(${r}, ${g}, ${b}, 0.2)`
			})

		this.offscreenRenderer.decorate((context: CanvasRenderingContext2D) => {
			context.strokeStyle = "transparent"
			context.fillStyle = `rgba(${r}, ${g}, ${b}, 0.2)`
		})

		this.updateRenderCacheKey()
	}

	public getDirectRenderer = (options?: TraceRendererOptions) => {
		if (options?.xScale) {
			this.directRenderer.xScale(options.xScale)
		}

		if (options?.yScale) {
			this.directRenderer.yScale(options.yScale)
		}

		return this.directRenderer
	}

	public getOffscreenRenderer = (options?: TraceRendererOptions) => {
		if (options?.xScale) {
			this.offscreenRenderer.xScale(options.xScale)
		}

		if (options?.yScale) {
			this.offscreenRenderer.yScale(options.yScale)
		}

		return this.offscreenRenderer
	}

	render(): void {
		this.pageManager.getPagesInView().forEach(page => this.d3Trace.renderPage(page))
		this.fillInPageGaps()
	}

	public renderTimeSeriesData(first: TimeSeriesData, second: TimeSeriesData, page: ModalityPage, renderer: (drawableData: Iterable<[number, number, number]>) => void) {
		// If there is only one data point, just pretend that the value is constant.
		first = this.checkIfOnlyOneValue(first, page)
		second = this.checkIfOnlyOneValue(second, page)

		const chunks = this.drawableDataGenerator(first, second, page)

		for (const chunk of chunks) {
			renderer(chunk)
		}

		// The edges can be used to redraw the gap between pages when the underlying data is unavailable
		const edges = [
			[first.times[0], first.data[0], second.data[0]],
			[first.times[first.times.length - 1], first.data[first.data.length - 1], second.data[second.data.length - 1]]
		]

		return edges as [number | undefined, number, number][]
	}

	public getRenderCacheKey(): string {
		return this.renderCacheKey
	}

	public renderPage(page: ModalityPage, reactCallbacks: ModalityGraphGroupReactCallbacks, offscreenXScale: ScaleTime<any, any, any>, offscreenCanvas: OffscreenCanvas, context: CanvasRenderingContext2D, pageRectangle: PageRectangle) {
		const { x, y, width, height } = pageRectangle

		const firstDataObjectId = reactCallbacks.dataSourceMap.get(this.config.first.dataSource) ?? Infinity
		const firstTraceData = page.data.get(firstDataObjectId)?.get(this.config.first.dataKey)

		const secondDataObjectId = reactCallbacks.dataSourceMap.get(this.config.second.dataSource) ?? Infinity
		const secondTraceData = page.data.get(secondDataObjectId)?.get(this.config.second.dataKey)

		if (firstTraceData !== undefined && secondTraceData !== undefined) {
			offscreenXScale.domain([page.startTime, page.endTime]).range([0, width])

			// If there is no data, still put the cached render in the render cache 
			// so we won't draw a gray rectangle until we reload this page.
			if (firstTraceData.data.length === 0 || secondTraceData.data.length === 0) {
				page.updateRenderCache(firstDataObjectId, this.renderCacheKey, { bitmap: offscreenCanvas.transferToImageBitmap(), dirty: false, edges: [] })
				return
			}

			const firstTimeSeriesData = getTimeSeriesData(firstTraceData, this.config.first)
			const secondTimeSeriesData = getTimeSeriesData(secondTraceData, this.config.second)

			const edges = this.renderTimeSeriesData(firstTimeSeriesData, secondTimeSeriesData, page, this.getOffscreenRenderer({ xScale: offscreenXScale, yScale: this.config.yScale }))

			const bitmap = offscreenCanvas.transferToImageBitmap()
			page.updateRenderCache(firstDataObjectId, this.renderCacheKey, { bitmap, dirty: false, edges: edges })
			context.drawImage(bitmap, x, y)
		} else if (!page.loaded) {
			context.fillStyle = this.placeholderColor
			context.fillRect(x, y, width, height)
		}

		this.fillInPageGaps()
	}

	public fillInPageGaps() {
		const gapsToDraw: Array<[number, number, number][]> = []

		// If we pretend that the traces are drawn as lines, we can reuse the code being used to calculate the gaps.
		const firstRenderStrategy = new LineRenderStrategy(this.pageManager, this.d3Trace, { ...this.config.first, color: "#000", renderStrategy: RenderStrategy.LINE, yScale: this.config.yScale })
		const secondRenderStrategy = new LineRenderStrategy(this.pageManager, this.d3Trace, { ...this.config.second, color: "#000", renderStrategy: RenderStrategy.LINE, yScale: this.config.yScale })

		const firstGaps = firstRenderStrategy.getPageGaps()
		const secondGaps = secondRenderStrategy.getPageGaps()

		firstGaps.forEach((firstGap, index) => {
			const secondGap = secondGaps[index]

			if (!firstGap || !secondGap) {
				return
			}

			if (gapsToDraw.length === 0) {
				gapsToDraw.push([])
			}

			const currentIndex = gapsToDraw.length - 1

			// I know that this looks like a mess, that's because it is.
			const firstGapTimes = firstGap.map(([time]) => time).filter(time => time !== undefined) as number[]
			const earliestFirstGapTime = Math.min(...firstGapTimes)
			const latestFirstGapTime = Math.max(...firstGapTimes)

			const secondGapTimes = secondGap.map(([time]) => time).filter(time => time !== undefined) as number[]
			const earliestSecondGapTime = Math.min(...secondGapTimes)
			const latestSecondGapTime = Math.max(...secondGapTimes)

			const interpolationStartTime = Math.min(earliestFirstGapTime, earliestSecondGapTime)
			const interpolationEndTime = Math.max(latestFirstGapTime, latestSecondGapTime)

			// Because the gaps can have different timestamps for each time series dataset, we need to linearly interpolate the values.
			// We only need to put the data in the interpolator that overlaps with the region
			const [firstInterpolator, firstTimes, firstCrossesPageBoundary] = this.getInterpolator(this.config.first, interpolationStartTime, interpolationEndTime, earliestFirstGapTime, latestFirstGapTime)
			const [secondInterpolator, secondTimes, secondCrossesPageBoundary] = this.getInterpolator(this.config.second, interpolationStartTime, interpolationEndTime, earliestSecondGapTime, latestSecondGapTime)

			// If only one of the modalities crosses the page boundary, we don't want to fill in the page gap 
			// because the area is not continuous even if one of the modalities is.
			if (firstCrossesPageBoundary && secondCrossesPageBoundary) {
				const times: number[] = [...new Set([...firstTimes, ...secondTimes])].sort()

				for (const time of times) {
					gapsToDraw[currentIndex].push([time, firstInterpolator(time), secondInterpolator(time)])
				}

				gapsToDraw.push([])
			}
		})

		fillInPageGaps(gapsToDraw, this.config.xScale, this.config.yScale, this.getDirectRenderer())
	}

	private getInterpolator(config: BaseTraceConfig & TraceDataConfig, interpolateStartTime: number, interpolateEndTime: number, modalityStartTime: number, modalityEndTime: number): [ScaleLinear<any, any, any>, number[], boolean] {
		const dataObjectId = this.d3Trace.getDataObjectId(config) ?? Infinity
		const [currentPage, nextPage] = this.pageManager.getPagesInView()
		const previousPage = this.pageManager.getPreviousPage(currentPage)
		const pageAfterView = this.pageManager.getNextPage(nextPage)

		const crossesPageBoundary: boolean = [previousPage, currentPage, nextPage, pageAfterView].filter(page => page !== undefined && !(page.endTime < modalityStartTime || page.startTime > modalityEndTime)).length > 1
		const interpolatePages: ModalityPage[] = [previousPage, currentPage, nextPage, pageAfterView].filter(page => page !== undefined && !(page.endTime < interpolateStartTime || page.startTime > interpolateEndTime)) as ModalityPage[]

		let allTimes: number[] = []
		let allData: number[] = []

		for (const page of interpolatePages) {
			const data = page?.data.get(dataObjectId)?.get(config.dataKey)

			if (!data) {
				continue
			}

			const samplingPeriod = page.timingInformation.get(dataObjectId)?.get(config.dataKey)?.samplingPeriod

			if (!samplingPeriod) {
				continue
			}

			const timeSeriesData = getTimeSeriesData(data, config)

			// We need to get the data a little bit on either side of the region. So, add a little bit to make sure we are getting one extra data point.
			const beforeIndex = bisectLeft(timeSeriesData.times as number[], interpolateStartTime - samplingPeriod)
			const afterIndex = bisectRight(timeSeriesData.times as number[], interpolateEndTime + samplingPeriod)

			allTimes.push(...timeSeriesData.times.slice(beforeIndex, afterIndex) as number[])
			allData.push(...timeSeriesData.data.slice(beforeIndex, afterIndex))
		}

		const interpolator = scaleLinear().domain(allTimes).range(allData) // The interpolator should have more context to accurately fill the region
		const fillTimes = [...new Set(allTimes.filter(time => time >= interpolateStartTime && time <= interpolateEndTime))] // We only want to fill the times that are within the region requested.

		return [interpolator, fillTimes, crossesPageBoundary]
	}

	private updateRenderCacheKey = () => {
		const { graphId, first, second, color } = this.config

		let firstCompositeIndex = undefined
		let secondCompositeIndex = undefined

		if (first.isCompositePart) {
			firstCompositeIndex = first.compositeIndex
		}

		if (second.isCompositePart) {
			secondCompositeIndex = second.compositeIndex
		}

		this.renderCacheKey = `${graphId}-${first.dataKey}-${first.dataSource}-${firstCompositeIndex}-${second.dataKey}-${second.dataSource}-${secondCompositeIndex}-rgb(${color.r}, ${color.g}, ${color.b})`
	}

	private drawableDataGenerator(first: TimeSeriesData, second: TimeSeriesData, page: ModalityPage): [number, number, number][][] {
		const continuousChunks: [number, number, number][][] = []
		let currentChunkIndex = 0

		const firstDataObjectId = this.d3Trace.getDataObjectId(this.config.first)
		const secondDataObjectId = this.d3Trace.getDataObjectId(this.config.second)

		// Because the data can exist on separate grids, create functions that to get the value at any time
		const firstNullIndexes = first.data.map((value, index) => value === null || Number.isNaN(value) ? index : -1).filter(index => index > -1)
		const firstDataInterpolator = scaleLinear()
			.domain(first.times.filter((time, index) => time !== undefined && !firstNullIndexes.includes(index)) as number[])
			.range(first.data.filter((_, index) => !firstNullIndexes.includes(index)))

		const secondNullIndexes = second.data.map((value, index) => value === null || Number.isNaN(value) ? index : -1).filter(index => index > -1)
		const secondDataInterpolator = scaleLinear()
			.domain(second.times.filter((time, index) => time !== undefined && !secondNullIndexes.includes(index)) as number[])
			.range(second.data.filter((_, index) => !secondNullIndexes.includes(index)))

		if (firstDataObjectId === undefined || secondDataObjectId === undefined) {
			throw new Error("One of the data object IDs was not defined.")
		}

		// Store the gaps of each time series so that we don't accidentally fill in the gaps
		const firstGapIndexes = page.timingInformation.get(firstDataObjectId)?.get(this.config.first.dataKey)?.gapIndexes ?? []
		const secondGapIndexes = page.timingInformation.get(secondDataObjectId)?.get(this.config.second.dataKey)?.gapIndexes ?? []

		// In the case of composite data, we also need to detect whether the value is NaN, to say whether it is a gap or not.
		const firstCombinedGapIndexes = [...new Set([...getGapIndexes(firstGapIndexes, this.config.first), ...firstNullIndexes])]
		const secondCombinedGapIndexes = [...new Set([...getGapIndexes(secondGapIndexes, this.config.second), ...secondNullIndexes])]
		const firstGapTimes = firstCombinedGapIndexes.map(index => [first.times[index-1], first.times[index]])
		const secondGapTimes = secondCombinedGapIndexes.map(index => [second.times[index-1], second.times[index]])

		const times: number[] = ([...new Set([...first.times, ...second.times])].filter(time => time !== undefined) as number[]).sort()

		// Draw the interpolated area to the next non-gap timestamp
		for (const time of times) {
			if (continuousChunks.length === 0) {
				continuousChunks.push([])
			}

			// We don't want to interpolate beyond where the data exists.
			const firstTimes = first.times.filter(time => time !== undefined) as number[]
			const secondTimes = second.times.filter(time => time !== undefined) as number[]

			if ((firstTimes.length > 0 && (time < firstTimes[0] || time > firstTimes[firstTimes.length - 1]))
				|| (secondTimes.length > 0 && (time < secondTimes[0] || time > secondTimes[secondTimes.length - 1]))
			) {
				if (continuousChunks[currentChunkIndex].length > 0) {
					continuousChunks.push([])
					currentChunkIndex++
				}

				continue
			}

			const firstHasGap = firstGapTimes?.some(([start, end]) => {
				return (start !== undefined && end !== undefined && time > start && time <= end)
					|| (start !== undefined && end === undefined && time > start)
					|| ((end !== undefined && start === undefined && time < end))
			})

			const secondHasGap = secondGapTimes?.some(([start, end]) => {
				return (start !== undefined && end !== undefined && time > start && time <= end)
					|| (start !== undefined && end === undefined && time > start)
					|| ((end !== undefined && start === undefined && time < end))
			})

			if (firstHasGap || secondHasGap) {
				continuousChunks.push([])
				currentChunkIndex++

				if (this.config.first.isCompositePart || this.config.second.isCompositePart) {
					// In the case of composite data, a gap usually means that we should skip the point.
					// This isn't entirely correct. Really, there should be a flag for whether the gap is a skip or not.
					// But for right now, the composite data that we do read is only from analytics, which are regularly sampled.
					continue
				}
			}

			continuousChunks[currentChunkIndex].push([time, firstDataInterpolator(time), secondDataInterpolator(time)])
		}

		return continuousChunks
	}

	private checkIfOnlyOneValue(timeSeriesData: TimeSeriesData, page: ModalityPage): TimeSeriesData {
		if (timeSeriesData.data.length === 1) {
			return {
				data: new Float32Array([timeSeriesData.data[0], timeSeriesData.data[0]]),
				times: [page.startTime, page.endTime]
			}
		}

		return timeSeriesData
	}
}
