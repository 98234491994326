import { atomFamily } from "recoil";
import { LayoutWindowId } from "../Types/LayoutWindowId";
import { getDefaultVisualizationAtom } from "./Visualizations";
import { MAPOptPlotConfig } from "../Types/MAPOptPlot";

export const mapOptPlotConfigsAtom = atomFamily<MAPOptPlotConfig, LayoutWindowId>({
    key: "mapoptPlotConfigs",
    default: () => ({
        ...getDefaultVisualizationAtom(),
        abpModality: "ABP_na",
        stO2Modality: "stO2_na"
    })
})
