import { Selection, EnterElement, ScaleTime } from "d3"
import { D3OneToOneRenderable } from "./D3OneToOneRenderable"
import { ReactCallbacks } from "../../Types/ReactCallbacks"
import { ImagePage } from "../../Data/ImagePage"
import { ImageTimeSeriesPageManager } from "../../Data/ImageTimeSeriesPageManager"
import { getColors, MobergTheme } from "../../../../../../Moberg"

export type D3ImageCanvasConfig = {
	graphId: string
	dataObjectId: number
	viewScale: ScaleTime<any, any, any>
	height: number
}

export class D3ImageCanvas extends D3OneToOneRenderable<SVGGElement, SVGForeignObjectElement, D3ImageCanvasConfig> {
	private context: CanvasRenderingContext2D | null | undefined
	private pageManager: ImageTimeSeriesPageManager
	private pageRectangle = { x: 0, y: 0, width: 0, height: 0 }
	private dataKey = "image"
	private placeholderColor = getColors(MobergTheme.GRAY).main

	constructor(root: SVGGElement, config: D3ImageCanvasConfig, pageManager: ImageTimeSeriesPageManager, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-image-canvas", reactCallbacks)
		this.pageManager = pageManager
		this.render()
	}

	public renderPage = (page: ImagePage | undefined) => {
		if (!page || !this.context) {
			return
		}

		const { x, y, width, height } = this.getPageRectangle(page)

		this.context.clearRect(x, y, width, height)

		// Check if there is a cached render at the same resolution.
		const cachedRender = page.renderCache.get(this.config.dataObjectId)?.get(this.dataKey)

		if (cachedRender && !cachedRender.dirty) {
			const bitmap = cachedRender.bitmap

			if (width !== bitmap.width || height !== bitmap.height) {
				console.warn("the image is the wrong size and may appear pixelated! Click to refresh")
			}

			this.context.drawImage(bitmap, 0, 0, page.width, page.height, x, y, width, height)

			return
		}

		const imageData = page.data.get(this.config.dataObjectId)?.get(this.dataKey)

		// If the page is not loaded or there is no cached image, show loading.
		if (!page.loaded || !imageData) {
			this.context.fillStyle = this.placeholderColor
			this.context.fillRect(x, y, width, height)
			return
		}

		if (imageData) {
			const blob = new Blob([imageData], { type: "image/gif" })
			createImageBitmap(blob)
				.then(bitmap => {
					if (this.context) {
						page.updateRenderCache(this.config.dataObjectId, this.dataKey, { bitmap, dirty: false, edges: [] })
						this.context.clearRect(x, y, width, height)
						this.context.drawImage(bitmap, 0, 0, page.width, page.height, x, y, width, height)
					}
				})
				.catch(error => console.warn(error))
		}
		
	}

	private getPageRectangle = (page: ImagePage) => {
		const x1 = this.config.viewScale(page.startTime)
		const x2 = this.config.viewScale(page.endTime)

		// rounding reduces aliasing  
		this.pageRectangle.x = Math.round(this.config.viewScale(page.startTime))
		this.pageRectangle.width = Math.round(x2 - x1)
		this.pageRectangle.height = this.getHeight()

		return this.pageRectangle
	}

	private getWidth = () => Math.round(this.config.viewScale?.range()[1])
	private getHeight = () => Math.round(this.config.height)

	protected enter(newElements: Selection<EnterElement, D3ImageCanvasConfig, any, any>): Selection<SVGForeignObjectElement, D3ImageCanvasConfig, SVGGElement, any> {
		const foreignObject = newElements.append("foreignObject").attr("class", this.className)
		const canvas = foreignObject.append("xhtml:canvas") as Selection<HTMLCanvasElement, any, any, any>

		const width = this.getWidth()
		const height = this.getHeight()

		foreignObject.attr("width", width).attr("height", height)
		canvas.attr("width", width).attr("height", height)

		const canvasNode = canvas.node()

		if (canvasNode != null) {
			this.context = canvasNode.getContext("2d") ?? undefined
		}

		this.pageManager.getPagesInView().forEach(page => this.renderPage(page))

		return foreignObject
	}

	protected update(updatedElements: Selection<SVGForeignObjectElement, D3ImageCanvasConfig, any, any>): Selection<SVGForeignObjectElement, D3ImageCanvasConfig, SVGGElement, any> {
		const updatedCanvas = updatedElements.select("canvas")

		const width = this.getWidth()
		const height = this.getHeight()

		updatedElements.attr("width", width).attr("height", height)
		updatedCanvas.attr("width", width).attr("height", height)

		this.pageManager.getPagesInView().forEach(page => this.renderPage(page))

		return updatedElements
	}
}
