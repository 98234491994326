import { MobergAnimationTimingMs } from "../../../../../../../../Moberg";
import { CPPOptData } from "../../../../Types/CPPOptPlot";
import { D3ClipPathConfig } from "../../../D3/D3ClipPath";
import { D3HorizontalLinesConfig } from "../../../D3/D3HorizontalLines";
import { D3ParabolaConfig } from "../../../D3/D3Parabola";
import { D3PointConfig } from "../../../D3/D3Point";
import { D3ValueAxisConfig } from "../../../D3/D3ValueAxis";
import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline";
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder"
import { HistogramXAxisConfig } from "../../Histogram/D3/D3HistogramXAxis";
import { D3CPPOptDebugInfoConfig } from "./D3CPPOptDebugInfo";
import { D3ErrorBarsConfig } from "./D3CPPOptErrorBars";
import { D3CPPOptPlot } from "./D3CPPOptPlot";

export class D3CPPOptPlotConfigurationBuilder extends ConfigurationBuilder<D3CPPOptPlot> {
	private bins = [42.5, 47.5, 52.5, 57.5, 62.5, 67.5, 72.5, 77.5, 82.5, 87.5, 92.5, 97.5, 102.5, 107.5, 112.5, 117.5]
	public transitionDuration: number = MobergAnimationTimingMs.SLOW
	public cppOptData: CPPOptData = {
		CPPOpt: null,
		LLA: null,
		ULA: null,
		includedDataPercentage: null,
		fitType: null,
		fitSpan: null,
		yValues: new Array(this.bins.length).fill(null),
		errors: new Array(this.bins.length).fill(null),
		includedInFit: new Array(this.bins.length).fill(null),
		dataPercentages: new Array(this.bins.length).fill(null),
		coefficients: [null, null, null],
		weight: null
	}

    getClipPathConfig = (): D3ClipPathConfig => {
		return {
			id: this.getClipPathId(),
			boundingBox: this.visualization.boundingBox
		}
	}

    getTimelineConfig = (): D3TimelineConfig => {
		const timelineUpdateTimes = (start: number, end: number) => {
			this.visualization.viewTimesChanged(start, end)
			this.visualization.updateLinkedWindows()
		}

		return {
			id: this.visualization.config.id,
			viewScale: this.visualization.config.viewScale,
			width: this.visualization.boundingBox.width,
			annotations: [],
			fileScale: this.visualization.config.fileScale,
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
			updateViewTimes: timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
		}
	}

    getXAxisConfig = (): HistogramXAxisConfig => ({
        scale: this.visualization.xScaleBand,
        boundingBox: this.visualization.boundingBox,
		label: "CPP (mmHg)",
    })

	getYAxisConfig = (): D3ValueAxisConfig => ({
		graphId: this.visualization.config.id,
		scale: this.visualization.yScale,
		label: "PRx",
		onDrag: this.visualization.onYAxisDrag,
		onDragEnd: this.visualization.onYAxisDragEnd,
		onDragStart: this.visualization.onYAxisDragStart,
		onDoubleClick: this.visualization.autoScale
	})

	getHorizontalLinesConfig = (): D3HorizontalLinesConfig => ({
		yScale: this.visualization.yScale,
		width: this.visualization.boundingBox.width,
	})

	getParabolaConfig = (): D3ParabolaConfig => ({
		xScale: this.visualization.xScaleLinear,
		yScale: this.visualization.yScale,
		coefficients: this.cppOptData.coefficients,
		clipPathId: this.getClipPathId(),
		color: "black",
		isDashed: true,
		transitionDuration: this.transitionDuration,
		applyInverseFisher: true
	})

	getErrorBarsConfig = (): D3ErrorBarsConfig => ({
		yScale: this.visualization.yScale,
		xScale: this.visualization.xScaleLinear,
		clipPathId: this.getClipPathId(),
		bars: this.bins.map((x, index) => ({
			x,
			y: this.cppOptData.yValues[index],
			error: this.cppOptData.errors[index],
			includedInFit: this.cppOptData.includedInFit[index] === 1,
			dataPercentage: this.cppOptData.dataPercentages[index],
		})).filter(bar => bar.y != null),
		transitionDuration: this.transitionDuration
	})

	getMinimumPointConfig = (x: number, y: number): D3PointConfig => ({
		x,
		y,
		xScale: this.visualization.xScaleLinear,
		yScale: this.visualization.yScale,
		color: "blue",
		size: 5,
		clipPathId: this.getClipPathId(),
		transitionDuration: this.transitionDuration
	})

	getDebugInfoConfig = (): D3CPPOptDebugInfoConfig => ({
		bins: this.bins,
		cppoptData: this.cppOptData
	})

	updateCPPOptData = (data: CPPOptData) => {
		this.cppOptData = data
	} 

	getLLAPointConfig = (x: number, y: number): D3PointConfig => ({
		color: "purple",
		size: 5,
		x,
		y,
		xScale: this.visualization.xScaleLinear,
		yScale: this.visualization.yScale,
		clipPathId: this.getClipPathId(),
		transitionDuration: this.transitionDuration
	})

	getULAPointConfig = (x: number, y: number): D3PointConfig => ({
		color: "purple",
		size: 5,
		x,
		y,
		xScale: this.visualization.xScaleLinear,
		yScale: this.visualization.yScale,
		clipPathId: this.getClipPathId()
	})

    private getClipPathId = () => `d3-clip-path-${this.visualization.config.id}`

}