import { ScaleBand, ScaleLinear, ScaleTime } from "d3"
import { PageRectangle, TraceConfig } from "../../../../Types/Trace"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"


type YScale = ScaleLinear<any, any, any> | ScaleBand<any>

export type TraceRendererOptions = {
	xScale?: ScaleTime<any, any, any>
	yScale?: YScale
}

export interface TraceRenderStrategy {
	updateConfig(traceConfig: TraceConfig): void
    getOffscreenRenderer(options?: TraceRendererOptions): any
    getDirectRenderer(options?: TraceRendererOptions): any
	render(): void 
	getRenderCacheKey(): string
	renderPage(page: ModalityPage, reactCallbacks: ReactCallbacks<any>, offscreenXScale: ScaleTime<any, any, any>, offscreenCanvas: OffscreenCanvas, context: CanvasRenderingContext2D, pageRectangle: PageRectangle): void
}

export function fillInPageGaps(gapFills: Array<(number | undefined)[][] | undefined>, xScale: ScaleTime<any, any, any>, yScale: ScaleLinear<any, any, any>, directRenderer: any) {
	const context = directRenderer.context()
	
	gapFills.forEach(gapFill => {
		if (!gapFill || gapFill.length < 2) {
			return
		}

		// Clear the rectangle that covers the gap
		const startTime = gapFill[0][0]
		const endTime = gapFill[gapFill.length-1][0]

		if (!startTime || !endTime) {
			return
		}
		
		const x = xScale(startTime)
		const height = yScale.range()[0]
		const width = xScale(endTime) - x
		context.clearRect(x, 0, width, height)

		// Fill in the gap
		directRenderer(gapFill)
	})
}
