import { TraceDataConfig } from "../Types/Trace"

export type TraceData = TimeSeriesData | CompositeTimeSeriesData

export type TimeSeriesData = {
    times: (number | undefined)[]
    data: Float32Array
}

export type CompositeTimeSeriesData = {
    times: (number | undefined)[]
    data: Float32Array[]
}

export function getSignal(traceData: TraceData, config: TraceDataConfig): Float32Array {
    if (config.isCompositePart && isCompositeTimeSeriesData(traceData)) {
        return traceData.data[config.compositeIndex]
    }

    return (traceData as TimeSeriesData).data
}

export function getTimeSeriesData(traceData: TimeSeriesData | CompositeTimeSeriesData, config: TraceDataConfig): TimeSeriesData {
    if (config.isCompositePart && isCompositeTimeSeriesData(traceData)) {
        return { times: traceData.times, data: traceData.data[config.compositeIndex] }
    }

    return traceData as TimeSeriesData
}

export function getGapIndexes(gapIndexes: number[] | number[][], config: TraceDataConfig): number[] {
    if (config.isCompositePart && Array.isArray(gapIndexes[0])) {
        return gapIndexes[config.compositeIndex] as number[]
    }

    return gapIndexes as number[]
}

function isCompositeTimeSeriesData(traceData: TimeSeriesData | CompositeTimeSeriesData): traceData is CompositeTimeSeriesData {
    return Array.isArray(traceData.data[0]) || traceData.data[0] instanceof Float32Array;
}

export function findMostRelevantCompositeRow(compositeData: CompositeTimeSeriesData, targetTime: number): number {
    // Linear search (on a sorted list) for the most relevant data point, O(N) in the worst case.
    // We're not working with super large arrays, so binary search may not offer much of an improvement anyway.
    const times = compositeData.times
    let mostRelevantIndex = -1

    const values = compositeData.data[0] // in this case, the CPPopt or MAPopt values. We want to skip over them if they are undefined.

    for (let i = 0; i < times.length; i++) {
        const time = times[i]

        // Skip times and composite values that are undefined
        if (time === undefined || values === undefined || values[i] === undefined) { 
            continue
        }

        // If the data is defined, it is the closest to the target time.
        if (time <= targetTime) {
            mostRelevantIndex = i
        }

        // If we go past the target time, then we have found the most relevant data point.
        if (time > targetTime) {
            break
        }
    }

    return mostRelevantIndex
}
