import { Selection, EnterElement, ScaleTime, DragBehavior } from "d3";
import { D3OneToOneRenderable } from "../D3OneToOneRenderable";
import { ReactCallbacks } from "../../../Types/ReactCallbacks";
import { getColors, MobergTheme } from "../../../../../../../Moberg";

type D3TimelineSliderConfig = {
    viewScale: ScaleTime<any, any, any>
    fileScale: ScaleTime<any, any, any>
    dragBehavior: DragBehavior<any, any, any>
    canInteract: boolean
    color?: string
}

export class D3TimelineSlider extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3TimelineSliderConfig> {
    readonly draggingFillColor: string = "rgba(32, 125, 234, 0.2)"
    readonly idleFillColor: string = "rgba(32, 125, 234, 0.13)"
    private placeholderColor = getColors(MobergTheme.GRAY).main
    private strokeColor = getColors(MobergTheme.BLUE).main
    private grayStrokeColor = getColors(MobergTheme.GRAY).active

    constructor(root: SVGGElement, config: D3TimelineSliderConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-timeline-slider", reactCallbacks)
        this.render()
    }

    enter = (newElements: Selection<EnterElement, D3TimelineSliderConfig, any, any>): Selection<any, any, any, any> => {
        const [startDate, endDate] = this.config.viewScale.domain()

        const x1 = this.config.fileScale(startDate) 
        const x2 = this.config.fileScale(endDate)
        const width = Math.max(0, x2 - x1)

        const slider =  newElements.append('rect')
    
        slider.attr("class", this.className)
            .attr("x", x1)
            .attr("y", 0)
            .attr("width", width)
            .attr("height", 30)
            .attr("fill", this.getFillColor())
            .attr("rx", 2)
            .attr("stroke-width", 2)
            .attr("stroke", this.getStrokeColor())
            .attr("cursor", this.config.canInteract ? "move" : "default")
            .call(this.config.dragBehavior)
            
        return newElements
    }

    update = (updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
        const [startDate, endDate] = this.config.viewScale.domain()
        const x1 = this.config.fileScale(startDate)
        const x2 = this.config.fileScale(endDate)
        const width = Math.max(0, x2 - x1)

        updatedElements
            .attr("x", x1)
            .attr("width", width)
            .attr("fill", this.getFillColor())
            .attr("stroke", this.getStrokeColor())
            .attr("cursor", this.config.canInteract ? "move" : "default")
            .raise()

        return updatedElements
    }

    private getFillColor = () => {
        if (!this.config.canInteract) {
            return this.placeholderColor
        }
        
        return this.config.color ?? this.idleFillColor
    }

    private getStrokeColor = () => {
        if (!this.config.canInteract) {
            return this.grayStrokeColor
        }

        return this.strokeColor
    }

}